import React, {useState, useEffect} from 'react';

const DoQuantity = ({singleProduct, manageCartNew, cart, setCanOrder, getItemTotal, item}) => {

    const [itemQty, setItemQty] = useState(0);

    useEffect(() => {
        setItemQty(getItemTotal(item.id))
    },[item, cart])

    const updateQty = (sign, itemQty) => {
        if (sign === '+') {
            setItemQty(parseInt(itemQty) + 1)
        } else if(sign === '-') {
            if (itemQty >= 1) {
                setItemQty(parseInt(itemQty) - 1)
            } else {
                manageCartNew('delete', item.id)
            }
        }
    }

    return (
        <div>   
            {singleProduct ? <button className='btn p-2 bg-dark text-white' onClick={() => setCanOrder(true)}>Order now</button> : <p className='h5'><button className='btn btn-white btn-sm border border-dark' onClick={() => updateQty('-', itemQty)}><span className='h6'>-</span></button> <button className='btn btn-white btn-sm border border-dark'><span className='h6'>{itemQty}</span></button> <button className='btn btn-white btn-sm border border-dark' onClick={() => { getItemTotal(item.id) >= item.totalQty ? alert('Your cart quantity for ' + item.name + ' exceeds avaialble quantity') : updateQty('+', itemQty) }}><span className='h6'>+</span></button> {itemQty > 0 && <button className='btn btn-sm bg-light border border-dark rounded-pill px-3 py-1 m-1' onClick={() => manageCartNew(itemQty, 'update', item.id, item.name, item.price)}>{`${itemQty === 1 ? 'Add' : 'Update'}`}</button>}</p>}    
        </div>           
    )
}

export default DoQuantity;