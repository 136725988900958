import React, { useState, useEffect, useContext } from 'react';
import { Header, Footer, ProductsHome } from './';
import { Link, useNavigate } from 'react-router-dom';
import { SiteContext } from '../context';
import { CategoriesMenu } from '../components';
import { removeTags, getHeading } from '../utils';
import Carousel from 'react-bootstrap/Carousel';
import Accordion from 'react-bootstrap/Accordion'
import Seo from './Seo';
import { AllProducts } from '../components';

const Home = () => {
    const { userData, siteMaxWidth, apiUrl, siteconfig, theme, products, formatAmount, isLoggedOn, categories, pages, handleCurrentPage, isMobile } = useContext(SiteContext);

    const navigate = useNavigate();

    const [animationPhotos, setAnimationPhotos] = useState([]);
    const [stories, setStories] = useState([]);
    const [testimonials, setTestimonials] = useState([]);
    const [clientLogos, setClientLogos] = useState([]);
    const [homeServices, setHomeServices] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getHomeAnimations();
        getArticles();
        getClientLogos();
        getHomeServices();
        getTestimonials();
        handleCurrentPage('home');
    }, [])

    const getHomeAnimations = () => {
        fetch(`${apiUrl}/api/homeanimations.php?action=fetch&type=Shop`, {
            method: 'get'
        }).then((res) => {
            return res.json()
        }).then((res) => {
            if (res.status === 200) {
                if (res.rows.length > 0) {
                    setAnimationPhotos(res.rows);
                }
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const getArticles = () => {
        fetch(`${apiUrl}/api/articles.php?action=fetchblog&page=1&limit=3`, {
            method: 'get'
        }).then((res) => {
            return res.json()
        }).then((res) => {
            if (res.status === 200) {
                if (res.rows.length > 0) {
                    setStories(res.rows);
                }
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const getClientLogos = () => {
        const logos = pages.filter((item) => item.cpagemenu === 'Clients');
        setClientLogos(logos);
    }

    const getHomeServices = () => {
        const homeservices = pages.filter((item) => item.cpagemenu === 'Services' && item.cpagename !== 'Services Overview');
        setHomeServices(homeservices);
        setLoading(false);
    }

    const getTestimonials = () => {
        fetch(`${apiUrl}/api/testimonials.php?action=fetch`, {
            method: 'get'
        }).then((res) => {
            return res.json()
        }).then((res) => {
            if (res.status === 200) {
                if (res.rows.length > 0) {
                    setTestimonials(res.rows);
                }
            }
        }).catch((err) => {
            console.log(err)
        })
    }


    const processText = (text) => {
        let thetext = removeTags(text);

        thetext = getHeading(thetext, 25);

        return thetext;
    }

    if (loading) {
        return (
            <div className='d-flex justify-content-center align-content-center my-5'>
                <div class="spinner-border text-secondary" role="status" />
            </div>
        )
    }


    return (
        <div style={{ 'backgroundColor': theme.backgroundColor, 'color': theme.color}}>
            <Seo
                title={`Home | ${siteconfig.shopname}`}
                description={`${siteconfig.shopname}`}
                url={`${apiUrl}`}
                img={`${apiUrl}/${siteconfig.shoplogo}`}
            />

            <Header />

            <div style={{'maxWidth':siteMaxWidth}} className='mx-auto container py-3'>

                <div className='row bg-success bg-opacity-25 py-2'>
                    <div className='col-3 d-none d-md-block rounded'>
                        <CategoriesMenu />
                    </div>
                    <div className={isMobile ? 'col-12' : 'col-9'}>
                    {animationPhotos.length > 0 &&
                <Carousel style={{ 'backgroundColor': 'black', 'height': isMobile ? 250 : 350 }}>
                    {animationPhotos.map((item) => {
                        return <Carousel.Item interval={3000} key={item.phid}>
                            <button onClick={() => navigate(`${item.phlink}`)}>
                            <img style={{ 'objectFit': 'contain', 'height': isMobile ? 250 : 350 }}
                                className="d-block w-100"
                                src={`${apiUrl}/${item.phphoto}`}
                                height={isMobile ? 250 : 350}
                                width='100%'
                                alt={item.phtitle}
                            />
                            </button>
                            
                            <Carousel.Caption>
                            </Carousel.Caption>
                        </Carousel.Item>
                    })
                    }
                </Carousel>}

                    </div>
                </div>

            <AllProducts />
            

            <div className='bg-white'>
                <div className='container'>
                    <h2 className='py-3'>Latest posts from our blog</h2>

                    <div className='row'>

                        {stories.length > 0 && stories.map((item, i) => {
                            return (
                                <div key={i} className='col-md-4 p-3 bg-light'>
                                    <Link className='text-decoration-none text-dark text-left' to={`/post/${item.titleslug}`} state={{ article: item }}>
                                        <div className='d-flex justify-content-center mb-2 p-3'>
                                            <img style={{ 'height': '250px', 'objectFit': 'cover' }} className='rounded img-fluid' src={`${apiUrl}/${item.artphoto}`} width={isMobile ? '80%' : '100%'} height={250} alt={item.title} />
                                        </div>
                                        <h4 className='px-3'>{getHeading(item.title, 10)}</h4>
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            <div className='py-5 bg-success bg-opacity-25'>

                <div className={`${isMobile ? 'w-75' : 'w-50'} my-3 py-2 mx-auto`}>
                    <h3 className='text-center text-dark'>What people say about us</h3>
                    <div className={`${isMobile ? 'w-75' : 'w-25'} border-danger border-bottom`} />
                </div>

                {testimonials.length > 0 &&
                    <Carousel className='w-75 mx-auto bg-success bg-opacity-25 text-center text-dark'>
                        {testimonials.map((item) => {
                            return <Carousel.Item interval={2000} key={item.tid}>

                                <div className={`${isMobile ? 'w-100' : 'w-75'} mx-auto mb-5 text-center`}>
                                    <div className='py-1'><q>{item.tmessage}</q></div>
                                    <div className='my-3'>
                                       <div className='d-flex justify-content-center'> {item.tphoto !== '' ?
                                            <img className="rounded-circle"
                                                src={`${apiUrl}/${item.tphoto}`}
                                                height={75}
                                                width={75}
                                                alt={item.phtitle}
                                            /> :
                                            <i className='bi bi-person h1'></i>
                                        }</div>
                                        <div className='h6'>{item.tcustomer} - {item.trole}</div>
                                    </div>

                                </div>

                                <Carousel.Caption>
                                </Carousel.Caption>
                            </Carousel.Item>
                        })
                        }
                    </Carousel>}
            </div>
            </div>

            <Footer />
        </div>
    )
}

export default Home;