import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import { SiteContext } from '../context';

const DashboardMenu = () => {
    const { isLoggedOn, userData, handleCurrentPage } = useContext(SiteContext);
    const userDataStorage = JSON.parse(localStorage.getItem('user'));

    const userDataInfo = userData || userDataStorage;

    const [showModal, setShowModal] = useState(false);


    const AdminMenu = () => {
        return (
            <div>
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' onClick={() => handleCurrentPage('')} to='/adminpages'>Site Pages</Link></div>
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' onClick={() => handleCurrentPage('')} to='/adminphotogallery'>Photo Gallery</Link></div>
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' onClick={() => handleCurrentPage('')} to='/adminphotoanimation'>Home page Photo Animation</Link></div>
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' onClick={() => handleCurrentPage('')} to='/adminproducts'>Products</Link></div>
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' onClick={() => handleCurrentPage('')} to='/transactions'>Orders</Link></div>
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' onClick={() => handleCurrentPage('')} to='/articles'>Articles</Link></div>
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' onClick={() => handleCurrentPage('')} to='/bannerads'>Banners</Link></div>
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' onClick={() => handleCurrentPage('')} to='/admincomments'>Comments</Link></div>                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' onClick={() => handleCurrentPage('')} to='/admintestimonials'>Testimonials</Link></div>
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' onClick={() => handleCurrentPage('')} to='/adminquoterequests'>Quote Requests</Link></div>
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' onClick={() => handleCurrentPage('')} to='/adminsiteconfig'>Site Config</Link></div>
                <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' onClick={() => handleCurrentPage('')} to='/adminnewsletters'>Newsletters</Link></div>
            </div>
        )
    }


    return (
        <div>
            <div className='p-3 d-block d-md-none'>
                <ReactModal
                    isOpen={showModal}
                    style={{
                        overlay: {
                            flex: 1,
                            justifyContent: 'center',
                            alignContent: 'center',
                            position: 'fixed',
                            top: '2%',
                            bottom: '2%',
                            left: 0,
                            right: 0,
                            width: '100%',
                            height: '96%',
                            backgroundColor: 'rgba(255, 255, 255, 0.75)'
                        },
                        content: {
                            position: 'absolute',
                            top: '10px',
                            left: '10px',
                            right: '10px',
                            bottom: '10px',
                            border: '1px solid #ccc',
                            background: '#fff',
                            overflow: 'auto',
                            WebkitOverflowScrolling: 'touch',
                            borderRadius: '4px',
                            outline: 'none',
                            padding: '10px'
                        }
                    }}>
                    <div>
                        <div className='d-flex justify-content-end'>
                            <span className='btn btn-dark mb-2 py-1' onClick={() => setShowModal(false)}><i className='bi bi-x h3 text-white'></i></span>
                        </div>
                        <div className='p-3'>
                            {isLoggedOn ?
                                <div className='text-dark'>
                                    <h5>Navigation {userDataInfo.role}</h5>
                                    <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/user'>Dashboard Home</Link></div>
                                    {userDataInfo && userDataInfo.role === 'admin' ?
                                        <div className='my-2'><AdminMenu /></div> :
                                        null
                                    }
                                    <div className='py-1 border-bottom'>
                                        <Link
                                            className='my-1 text-decoration-none'
                                            to="/profile">Profile</Link></div>
                                    <div className='py-1 border-bottom'>
                                        <Link
                                            className='my-1 text-decoration-none'
                                            to="/changepassword">Change Password</Link></div>
                                </div> :
                                <div>
                                    Not logged on
                                </div>}
                        </div>
                    </div>
                </ReactModal>
                <button className='btn-primary' onClick={() => setShowModal(true)}>Account Menu</button>
            </div>

            <div className='p-3 d-md-block d-none'>
                {isLoggedOn ?
                    <div className='text-dark'>
                        <h5>Navigation {userDataInfo.role}</h5>
                        <div className='py-1 border-bottom'><Link className='my-1 text-decoration-none' to='/user'>Dashboard Home</Link></div>
                        {userDataInfo && userDataInfo.role === 'admin' ?
                            <div className='my-2'><AdminMenu /></div> :
                            null
                        }
                        <div className='py-1 border-bottom'>
                            <Link
                                className='my-1 text-decoration-none'
                                to="/profile">Profile</Link></div>
                        <div className='py-1 border-bottom'>
                            <Link
                                className='my-1 text-decoration-none'
                                to="/changepassword">Change Password</Link></div>
                    </div> :
                    <div className='py-5 px-3 my-5'>
                        Not logged on
                    </div>}
            </div>
        </div>
    )
}

export default DashboardMenu;