import React, { useState, useEffect, useContext, useRef, useMemo, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { Header, Footer } from '../screens';
import { NavigationBar, Cart, Comments, Item, RandomProducts } from '.';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { SiteContext } from '../context';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    paymethod: Yup.string()
      .required('Choose a payment method please')
  });

const ProductDetail = () => {

  const {slug} = useParams();
  let navigate = useNavigate();

    const { userData, apiUrl, siteMaxWidth, formatAmount, cart, getOrderTotal, manageCart, singleProduct } = useContext(SiteContext);

    const [item, setItem] = useState({});
    const [gotItem, setGotItem] = useState(false);
    const [someItem, setSomeItem] = useState({});
    const [cartContents, setCartContents] = useState([]);
    const [orderTotal, setOrderTotal] = useState('');
    const [msg, setMsg] = useState('');
    const [canOrder, setCanOrder] = useState(false);
    const [key, setKey] = useState('Description');

  useEffect(() => {
    getItem();  
    window.scrollTo(0, 0);
    },[slug])

    const getItem = () => {

       fetch(`${apiUrl}/api/products.php?action=fetchsingle&slug=${slug}`, {
          method:'get'
        }).then((res) => res.json()
        ).then((res) => {
            if(res.status === 200) {
                setItem(res.product);
                setGotItem(true);
            } else if (res.status === 400) {
                setMsg('No product found')
                setTimeout(() => {
                  setMsg('')
                },5000)
            }
        }).catch((err) => {
            console.log(err)
        })
  
    }
    

  useEffect(() => {
    setCartContents(cart);
    setOrderTotal(getOrderTotal);
    cart.length > 0 && setCanOrder(true);
  },[cart]);

  
    const handleSubmit = (values, {setSubmitting, resetForm}) => {

      let formData = new FormData();

      formData.append('productid', singleProduct ? item.id : '');
      formData.append('productname', singleProduct ? item.name : '');
      formData.append('orderdetails', singleProduct ? [] : JSON.stringify(cart));
      formData.append('amount', singleProduct ? item.price : orderTotal);
      formData.append('customerid', userData.id);
      formData.append('customername', userData.name);
      formData.append('customerphone', userData.phone);
      formData.append('customeraddress', `${userData.address} ${userData.residencestate}`);
      formData.append('customerlatitude', userData.customerlatitude);
      formData.append('customerlongitude', userData.customerlongitude);
      formData.append('paymode', values.paymethod);
      formData.append('action', 'create');

      fetch(`${apiUrl}/api/orders.php`, {
          method: 'post',
          body: formData
      })
      .then((res) => res.json())
      .then((response) => {
          if(response.status === 200) {
              setMsg(response.msg);
              setTimeout(() => {
                setMsg('');
                },5000);
                setCanOrder(false);
                !singleProduct && manageCart('clear');
                navigate(`/ordercomplete/${response.orderref}`, {state: {'pay': values.paymethod}});
              } else if(response.status === 400){
              setMsg(response.msg);
              setTimeout(() => {
                setMsg('');
                },5000);  
            }
      })
      .catch((err) => {
          alert(err)
      })
  
  }


    
    return (
        <div>
          <Header />

        <div style={{'maxWidth': siteMaxWidth}} className='container'>

          <div className='row'>
            <div className='col-lg-12'>
              <Item item={item} handleSubmit={handleSubmit} apiUrl={apiUrl} formatAmount={formatAmount} />

              <RandomProducts />
            </div>
            {/* <div className='col-lg-4'>
            {userData ? 
              <div>
                <div className='border border-secondary bg-light rounded m-2 p-2'>
                  
                {!singleProduct && 
                  <div className="my-1 p-2 rounded">
                    <Cart />
                    <div className='d-flex justify-content-between'>
                        <button className='bg-secondary bg-opacity-50 p-2 my-1 text-dark h6 rounded' onClick={() => navigate(-1)}>Continue</button>
                        {cart.length > 0 && <button className='bg-success bg-opacity-75 p-2 my-1 text-white h6 rounded' onClick={() => navigate('/checkout')}>Checkout</button>}
                    </div>
                  </div>}

                    <div className='my-2 justify-content-center align-items-center'>
                        {canOrder && <div>
                    
                    {singleProduct ? 
                    <Formik
                    initialValues={{
                      paymethod:'Card'
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm });
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                    }) => (
                      <form onSubmit={handleSubmit} encype="multipart/form-data">
                        <div className='row'>
                          <div className='col-md-12'>


                        <div className='form-group my-1'>
                          <label>
                          <Field type="radio" name="paymethod" value="Cash" /> Cash
                          </label>
                          <label className='px-3'>
                          <Field type="radio" name="paymethod" value="Card" /> Card
                          </label>
                        </div>

                          </div>
                        </div>
                        <button
                            style={{'width': '100%'}}
                            type='submit'
                            onClick={handleSubmit}
                            title="Submit order"
                            disabled={!isValid || isSubmitting}
                              className='btn btn-primary my-1 py-2'
                          >
                              Submit order
                          </button>
                        
                          </form>
                    )}
                  </Formik> : 
                    <button style={{'width': '100%'}} className='btn-dark py-1 text-white h4 rounded' onClick={() => navigate('/checkout')}>Checkout</button>}

                    </div>}                
                    </div>
                </div>    
              </div> : 
              <div>
                  {!singleProduct && 
                  <div className="my-1 p-2 bg-info">
                    <Cart setCartContents={setCartContents} setOrderTotal={setOrderTotal} setCanOrder={setCanOrder} />
                    <div className='d-flex justify-content-between'>
                        <button className='bg-secondary bg-opacity-50 p-2 my-1 text-dark h6 rounded' onClick={() => navigate(-1)}>Continue</button>
                        <button className='bg-success bg-opacity-75 p-2 my-1 text-white h6 rounded' onClick={() => navigate('/checkout')}>Checkout</button>
                    </div>
                  </div>}

                <div className='d-flex justify-content-between my-3 py-2'>
                  <Link className='btn btn-primary' to='/login'>Login to place order</Link> 
                <Link className='btn btn-secondary' to='/register'>Signup</Link>
                </div>
                
              </div>} 
               </div> */}
          
          </div>

            {!item && <div>
              <div className='my-1 py-1'> {msg}</div>

              <p>Product not found</p>  
            </div>}
            </div>

            <Footer />
        </div>
    )
}

export default ProductDetail;