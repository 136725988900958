import React, { useContext } from 'react';
import { PaystackButton } from 'react-paystack';
import { SiteContext } from '../context';

const PayButton = ({order, setOrder, setMsg, setErrorMsg}) => {

const { apiUrl } = useContext(SiteContext);

const userData = JSON.parse(localStorage.getItem('user'));

const publicKey = "pk_live_24315e9e44f2aef9c009d799e937bbfb8f463fb0";

const componentProps = {
    email: userData.email,
    amount: order.amount * 100,
    reference: order.orderref,
    metadata: {
      name: userData.name,
      phone: userData.phone,
      orderid: order.id
    },
    publicKey,
    text: "Pay Now",
    onSuccess: () => {
        setMsg("Thanks for doing business with us! Come back soon!!");
        setTimeout(() => {
            setMsg('');
        },4000);
        handleCardPaymentUpdate();
    },
    onClose: () => alert("Wait! You are yet to complete your payment!"),
  }

  const handleCardPaymentUpdate = () => {
      doCardPaymentUpdate(order.orderref);
  }

  const doCardPaymentUpdate = (ord) => {
    let formData = new FormData();

    formData.append('user', userData.id);
    formData.append('order', ord);
    formData.append('action', 'cardupdate');

    fetch(`${apiUrl}/api/orders.php`, {
      method:'post',
      body: formData
    }).then((res) => {
        return res.json()
    }).then((res) => {
      if(res.status === 200) {
        setOrder(res.orderdetail);
        setMsg(res.msg)
        setTimeout(() => {
          setMsg('')
        },6000)  
      } else if (res.status === 400) {
        setErrorMsg(res.msg)
        setTimeout(() => {
          setErrorMsg('')
        },5000)  
      }
    }).catch((err) => {
        console.log(err)
    })
  }


    return (
        <div style={{'width':'50%'}} className='p-2 bg-light rounded text-center'>
                    {order.customerid == userData.id && order.paymentstatus === 'Unpaid' && <PaystackButton {...componentProps} />}
        </div>
    )
}

export default PayButton;