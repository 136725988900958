import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { NavigationBar } from '.';
import { SiteContext } from '../context';

const AllProducts = () => {

    const { apiUrl, formatAmount, categories, products } = useContext(SiteContext);

    const [msg, setMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    let navigate = useNavigate();

    return (
        <div>
         {msg !== '' ? <div className='my-2'><span className='p-2 my-2 border border-info text-dark'>{msg}</span></div> : null}


         {categories.sort((a,b) => a.pcid - b.pcid).map((item) => {
                const cat = item.pcatname;
                return (
                    <div className='my-3'>
                    <div className='m-0 px-2 py-2 h4 bg-success bg-opacity-50 d-flex justify-content-between align-items-center'>
                       <div>{cat}</div>
                       <div className='h6'><Link className='text-decoration-none text-dark' to={`/products/category/${item.pcatslug}`}>{`SEE ALL > `}</Link></div>
                    </div>
                    <div className='relative flex items-center border'>
                    <div id='slider' className='w-full h-[350px] overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide'>
                           {products.filter((item) => item.category === cat).map((item) => {
                                return (
                                    <button onClick={() => navigate(`/products/detail/${item.nameslug}`)} className='w-[220px] inline-block text-start cursor-pointer hover:scale-105 ease-in-out duration-300 p-2'>
                                        <img style={{'height':200, 'width':200, 'objectFit':'contain'}} key={item.name} src={`${apiUrl}/${item.picture}`} alt={item.name} />
                                        <p className='h6 text-wrap py-2'>{item.name}</p>
                                        <p>{formatAmount(item.price)}</p>
                                    </button>
                                )
                            })}
                        </div>
                        </div>
                    </div>
        
                )
            })
        }

        
        </div>
    )
}

export default AllProducts;