import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Header, Footer } from '.';
import { getHeading } from '../utils';
import { SiteContext } from '../context';

const ProductsCategory = () => {

    const { apiUrl, siteMaxWidth, formatAmount, products } = useContext(SiteContext);

    const [items, setItems] = useState([]);
    const [msg, setMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    let navigate = useNavigate();
    let {slug} = useParams();

    useEffect(() => {
        getProducts();
        window.scrollTo(0,0)
    },[slug])

    const getProducts = () => {

        let filteredProducts = products.filter((item) => item.category.toLowerCase().split(' ').join('-') === slug);
        setItems(filteredProducts);
    }


    return (
        <div>
            <Header />

        <div style={{'maxWidth': siteMaxWidth}}  className='container'>
    
        <div className='container mt-3 px-4 py-2 bg-light'>
            <Link to='/'>Home</Link> / <Link to='/products'>Products</Link> / <span>{slug.charAt(0).toUpperCase()+''+slug.slice(1).split('-').join(' ')}</span>  
        </div>

            {msg !== '' ? <div className='my-2'><span className='p-2 my-2 border border-info text-dark'>{msg}</span></div> : null}

                <div className='my-1'>
                    <div className='row my-3 py-3'>
                {items.map((item,i) => {
                    
                    return (
                    <div key={i} className='col-lg-3 col-md-4 col-sm-6 text-center'>
                        <button className='text-decoration-none text-dark border border-light bg-light rounded py-2 m-2' onClick={() => navigate(`/products/detail/${item.nameslug}`)}>
                                <div className='p-2 text-start'>
                                    <img style={{'objectFit':'cover', 'height':200}} className='rounded hover:scale-105 py-2' src={`${apiUrl}/${item.picture}`} width='100%' alt={item.name} />
                                <span className='py-1'>{getHeading(item.name,7)}</span><br />
                                <span className='text-muted small'>{item.category}</span><br />
                                <span className='h6'>{item.price !== 0 && formatAmount(item.price)}</span>
                                <div className='my-1 text-center'><button className='text-white bg-dark w-75 p-2 rounded'>Add to cart</button></div>    
                            </div>
                        </button>    
                    </div>
                    )})
                }
                </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ProductsCategory;