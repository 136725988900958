import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { CartBox } from '.';
import { SiteContext } from '../context';
import { Header, Footer } from '../screens';
import { paystack_logo } from '../assets';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
firstname: Yup.string()
  .label('First name')
  .required()
  .min(3, 'Must have at least 3 characters'),
lastname: Yup.string()
  .label('Last name')
  .required()
  .min(3, 'Must have at least 3 characters'),
phone: Yup.string()
  .label('Mobile phone')
  .required()
  .min(11, 'Must have at least 11 characters')
  .max(14, 'Must have a maximum of 14 characters'),
email: Yup.string()
  .label('Email')
  .email('Enter a valid email')
  .required('Please enter a registered email'),
address: Yup.string()
  .label('Address')
  .required()
  .min(5, 'Address must have at least 5 characters '),
city: Yup.string()
  .label('City')
  .required(),
paymethod: Yup.string()
      .required('Choose a payment method please')
  });

const Checkout = () => {

    const { apiUrl, isLoggedOn, formatAmount, cart, getOrderTotal, manageCart, currency, singleProduct, isMobile } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem('user'));
      
  const [item, setItem] = useState({});
  const [enteredCode, setEnteredCode] = useState('');
  const [checkCode, setCheckCode] = useState(false);
  const [shipping, setShipping] = useState([]);
  const [shippingAmt, setShippingAmt] = useState(0.00);
  const [subTotal, setSubTotal] = useState(0.00);
  const [total, setTotal] = useState(0.00);
  const [validCode, setValidCode] = useState('');
    const [cartContents, setCartContents] = useState([]);
    const [orderTotal, setOrderTotal] = useState('');
    const [msg, setMsg] = useState('');
    const [canOrder, setCanOrder] = useState(false);
    const [deliveryAddress, setDeliveryAddress] = useState('');
    const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  
  useEffect(() => {
    getShipping();
  }, [])
  
  
  const getShipping = () => {
    fetch(`${apiUrl}/api/shipping.php?action=fetchall`, {
      method: 'get'
    }).then((res) => {
      return res.json()
    }).then((res) => {

      if (res.status === 200) {
        if (res.rows.length > 0) {
          setShipping(res.rows);
          handleShipping();
        }
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  
    useEffect(() => {
      setCartContents(cart);
      setOrderTotal(getOrderTotal);
      setSubTotal(getOrderTotal);
      setTotal((parseFloat(subTotal) + parseFloat(shippingAmt)));
      cart.length > 0 && setCanOrder(true);
      cart.length === 0 && setShippingAmt(0.00);
    }, [cart]);
  
  const handleText = (event) => {
    setEnteredCode(event.target.value);
  }

  const handleCode = () => {

    if(enteredCode === '') {
      alert('Provide a discount code coupon');
      return;
    }
    setCheckCode(true);

    let formData = new FormData();

    formData.append('code', enteredCode);
    formData.append('action', 'checkcode');

    setLoading(true);
    
    fetch(`${apiUrl}/api/discounts.php`, {
      method: 'post',
      body: formData
  })
  .then((res) => res.json())
      .then((response) => {
      
    if (response.status === 200) {
      setLoading(false);
      setValidCode(response.discount);
      handleSubTotal(response.discount);
          } else if(response.status === 400){
            setLoading(false);
            setMsg(response.msg);
          setTimeout(() => {
            setMsg('');
            },5000);  
        }
  })
  .catch((err) => {
      console.log(err);
      setLoading(false);
  })    
  }

  const handleSubTotal = (percent) => {
    let sub;
    if (percent !== '') {
      sub = (parseInt(percent) * orderTotal) / 100;
      setSubTotal(parseFloat(orderTotal) - parseFloat(sub));
    } 
  }

  const handleShipping = () => {

    let filtered;
    if (isLoggedOn) {
        filtered = shipping.filter((item) => item.shippingcountry.toLowerCase() === userData.location_country.toLowerCase());
        
        if (filtered.length > 0) {
          setShippingAmt(filtered[0].shippingcost);
          setTotal(parseFloat(filtered[0].shippingcost) + parseFloat(subTotal));
         } 
    } 
  }

  const handleShippingUser = (loc) => {
   
    let filtered;
    
    filtered = shipping.filter((item) => item.shippingcountry.toLowerCase() === loc.toLowerCase());
    if (filtered.length > 0) {
        setShippingAmt(filtered[0].shippingcost);
        setTotal(parseFloat(filtered[0].shippingcost) + parseFloat(subTotal));
    } 
  }

    const handleSubmit = (values, {setSubmitting, resetForm}) => {

      if(!userData) {
        alert('Please login or signup to confirm your order');
        setSubmitting(false);
        return;
      }

      setLoading(true);

        let formData = new FormData();

        formData.append('productid', singleProduct ? item.id : '');
        formData.append('productname', singleProduct ? item.name : '');
        formData.append('currency', currency);
        formData.append('orderdetails', singleProduct ? [] : JSON.stringify(cart));
        formData.append('coupon', validCode);
        formData.append('shipping', shippingAmt);
        formData.append('amount', singleProduct ? item.price : parseFloat(subTotal) + parseFloat(shippingAmt));
        formData.append('customerid', userData.id);
        formData.append('customerfirstname', values.firstname);
        formData.append('customerlastname', values.lastname);
        formData.append('customeremail', values.email);
        formData.append('customerphone', values.phone);
        formData.append('customeraddress', values.address);
        formData.append('customercity', values.city);
        formData.append('customerstate', values.state);
        formData.append('customerpostalcode', values.postal);
        formData.append('customercountry', values.country);
        formData.append('paymode', values.paymethod);
        formData.append('action', 'create');

        fetch(`${apiUrl}/api/orders.php`, {
            method: 'post',
            body: formData
        })
        .then((res) => res.json())
          .then((response) => {

          if (response.status === 200) {
                setMsg(response.msg);
                setTimeout(() => {
                  setMsg('');
                  },3000);
                  setCanOrder(false);
                  setTimeout(() => {
                    setLoading(false);
                    !singleProduct && manageCart('clear');
                    navigate(`/ordercomplete/${response.orderref}`, {state: {'pay': values.paymethod}});
                  },4000)
                } else if(response.status === 400){
                  setLoading(false);
                  setMsg(response.msg);
                setTimeout(() => {
                  setMsg('');
                  },5000);  
              }
        })
        .catch((err) => {
            alert(err);
            setLoading(false);
        })
    
  }
  
  const handleCountry = (country) => {
    handleShippingUser(country);
  }

    
    return (
        <div>
            <Header />

        <div className={isMobile ? 'container' : 'w-75 mx-auto'}>

        <div className='container mt-3 px-4 py-2 bg-light'>
            <Link className='text-decoration-none' to='/'>Home</Link> / <Link className='text-decoration-none' to='/products'>Products</Link> / <span>Checkout</span>  
        </div>
        <h3 className='my-2 py-2'>Checkout</h3>

        {msg !== '' ? <div className='my-2'><span className='p-2 my-4 border border-info text-dark'>{msg}</span></div> : null}

        {loading ? <div className='d-flex justify-content-center align-content-center my-5'>
                                <div class="spinner-border text-secondary" role="status" />
                            </div> :
            <div className='row'>

                <div className='col-md-7 container'>
                  
              <div>
                <div className='my-2 py-3'>
                                      
                    <div className='my-2 justify-content-center align-items-center'>
                        {canOrder && <div>

                    <Formik
                              initialValues={{
                                firstname: userData ? userData.firstname : '',
                                lastname: userData ? userData.lastname : '',
                                address: userData ? userData.address : '',
                                city: '',
                                postal: '',
                                state: userData ? userData.location_state : '',
                                country: userData ? userData.location_country : '',
                                phone: userData ? userData.phone : '',
                                email: userData ? userData.email : '',
                                paymethod:'Card',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm });
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                    }) => (
                      <form onSubmit={handleSubmit} encype="multipart/form-data">
                              <div className='row'>
                                
                                  <div className='form-group my-1'>
                                  <h5>Payment options</h5>
                                  
                                    <label className='px-3 my-2'>
                                      <Field type="radio" name="paymethod" value="Card" /> <img className='inline-block mx-2 rounded' src={paystack_logo} width={150} height={60} alt='paystack' />
                                    </label>
                                    </div>
                                    
                                    <div className='form-group my-2 py-2'>
                                  <div className='d-flex justify-content-between align-items-center my-2 py-1'>
                                    <span className='h5'>Email address</span>
                                    {!userData && <span className='h6'>Registered? <Link className='text-decoration-none' to='/login'>Log in</Link> or <Link className='text-decoration-none' to='/signup'>Signup</Link></span>}
                                  </div>
                                                        
                                      <Field
                                        id="email"
                                        name="email"
                                        value={values.email}
                                        placeholder="Email address"
                                        onBlur={handleBlur('email')}
                                        autoCapitalize="none"
                                        style={{
                                          padding: 10,
                                      }}
                                      className="form-control rounded-3"
                                    />      
                                      {touched.email && errors.email && <div className='py-1'>
                                        <span className='text-danger'>{touched.email && errors.email}</span>
                                  </div>}
                                      </div>



                                    <div className='form-group my-2 py-2'>
                                    <h5>Shipping address</h5>
                                    <Field
                                          as="select"
                                          id="country"
                                          name="country"
                                          value={values.country}
                                    onBlur={() => {
                                      handleBlur('country');
                                      handleShippingUser(values.country);
                                    }
                                    }
                                          autoCapitalize="none"
                                          style={{
                                            padding: 10,
                                        }}
                                        className="form-control rounded-3"
                                        >
                                            <option value=''>Select country</option>
                                            <option value='Nigeria'>Nigeria</option>
                                    </Field>
                                        </div>


                                        <div className='col-md-6 form-group my-2 py-2'>
                                                        
                                        <Field
                                          id="firstname"
                                          name="firstname"
                                        value={values.firstname}
                                        placeholder="First name"
                                          onBlur={handleBlur('firstname')}
                                          autoCapitalize="none"
                                          style={{
                                            padding: 10,
                                        }}
                                        className="form-control rounded-3"
                                      />     
                                       {touched.firstname && errors.firstname && <div className='py-1'>
                                          <span className='text-danger'>{touched.firstname && errors.firstname}</span>
                                    </div>}
                                        </div>

                                        <div className='col-md-6 form-group my-2 py-2'>
                                                        
                                        <Field
                                          id="lastname"
                                          name="lastname"
                                          value={values.lastname}
                                          placeholder="Last name"
                                          onBlur={handleBlur('lastname')}
                                          autoCapitalize="none"
                                          style={{
                                            padding: 10,
                                        }}
                                        className="form-control rounded-3"
                                      />      
                                       {touched.lastname && errors.lastname && <div className='py-1'>
                                          <span className='text-danger'>{touched.lastname && errors.lastname}</span>
                                    </div>}
                                        </div>

                                        <div className='form-group my-2 py-2'>
                                                        
                                          <Field
                                            id="address"
                                            name="address"
                                            value={values.address}
                                            placeholder="Address"
                                            onBlur={handleBlur('address')}
                                            autoCapitalize="none"
                                            style={{
                                              padding: 10,
                                          }}
                                          className="form-control rounded-3"
                                        />      
                                          {touched.address && errors.address && <div className='py-1'>
                                            <span className='text-danger'>{touched.address && errors.address}</span>
                                      </div>}
                                          </div>

                                          <div className='col-md-6 form-group my-2 py-2'>
                                          
                                          <Field
                                            id="city"
                                            name="city"
                                            value={values.city}
                                            placeholder="City"
                                            onBlur={handleBlur('city')}
                                            autoCapitalize="none"
                                            style={{
                                              padding: 10,
                                          }}
                                          className="form-control rounded-3"
                                        />      
                                          {touched.city && errors.city && <div className='py-1'>
                                            <span className='text-danger'>{touched.city && errors.city}</span>
                                      </div>}
                                    </div>
                                    
                                    <div className='col-md-6 form-group my-2 py-2'>
                                                        
                                      <Field
                                        id="state"
                                        name="state"
                                        value={values.state}
                                        placeholder="State"
                                        onBlur={handleBlur('state')}
                                        autoCapitalize="none"
                                        style={{
                                          padding: 10,
                                      }}
                                      className="form-control rounded-3"
                                    />      
                                      {touched.state && errors.state && <div className='py-1'>
                                        <span className='text-danger'>{touched.state && errors.state}</span>
                                  </div>}
                                      </div>
                

                                      <div className='col-md-6 form-group my-2 py-2'>
                                        
                                        <Field
                                          id="postal"
                                          name="postal"
                                          value={values.postal}
                                          placeholder="Postal code"
                                          onBlur={handleBlur('postal')}
                                          autoCapitalize="none"
                                          style={{
                                            padding: 10,
                                        }}
                                        className="form-control rounded-3"
                                      />      
                                        {touched.postal && errors.postal && <div className='py-1'>
                                          <span className='text-danger'>{touched.postal && errors.postal}</span>
                                    </div>}
                                        </div>

                                        <div className='col-md-6 form-group my-2 py-2'>
                                        
                                        <Field
                                          id="phone"
                                          name="phone"
                                          value={values.phone}
                                          placeholder="Phone"
                                          onBlur={handleBlur('phone')}
                                          autoCapitalize="none"
                                          style={{
                                            padding: 10,
                                        }}
                                        className="form-control rounded-3"
                                      />      
                                        {touched.phone && errors.phone && <div className='py-1'>
                                          <span className='text-danger'>{touched.phone && errors.phone}</span>
                                    </div>}
                                        </div>
                                                      
                                    <div className='col-md-12'>

                          </div>
                        </div>
                        <button
                            style={{'width': '100%'}}
                            type='submit'
                            onClick={handleSubmit}
                            title="Submit order"
                            disabled={!isValid || isSubmitting}
                              className='btn btn-primary my-1 py-2'
                          >
                              Submit order
                          </button>
                        
                          </form>
                    )}
                  </Formik></div>}                
                    </div>
                </div>    
              </div> 

               
            </div>

              <div className={`${isMobile ? 'px-3' : 'px-5'} col-md-5 container`}>
                  
                <CartBox section='checkout' />

                {!checkCode ? <div className='border-dark d-flex justify-content-between'>
                  <div className='col'>
                    <input className='p-2 border rounded w-100' type={'text'} value={enteredCode} placeholder='Discount Code' onChange={(event) => handleText(event)} />
                  </div>
                  <div>
                    <button className='bg-dark rounded text-white px-3 py-2' onClick={() => handleCode()}>Apply</button>
                  </div>
                </div>:
                <div className='my-2 py-2 d-flex justify-content-between'>
                    <span className='h6'>Discount {(validCode === '' && msg !== '') ? msg : validCode !== '' ? validCode : null}</span>
                    <span className='h6 text-end'>{validCode !== '' && formatAmount((validCode * orderTotal) / 100)}</span>
                </div>}

                <div className='my-2 py-2 d-flex justify-content-between'>
                    <span className='h6'>Subtotal</span>
                    <span className='h6 text-end'>{formatAmount(subTotal)}</span>
                </div>

                <div className='my-2 py-2 d-flex justify-content-between'>
                  <span className='h6'>Shipping</span>
                    <span className='h6 text-end'>{formatAmount(shippingAmt)}</span>
                </div>

                <div className='my-2 py-2 border-top border-dark d-flex justify-content-between'>
                    <span className='h5'>Total</span>
                    <span className='h5 text-end'>{formatAmount((parseFloat(subTotal) + parseFloat(shippingAmt)))}</span>
                </div>

              <button className='bg-secondary bg-opacity-25 text-dark h6 my-2 p-2 rounded' onClick={() => navigate('/products')}>Continue shopping</button>

            </div>
            </div>}

            </div>
            <Footer />
        </div>
    )
}

export default Checkout;