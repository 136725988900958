import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { SiteContext } from '../context';

const CategoriesMenu = () => {

    const {categories, theme} = useContext(SiteContext);

    let navigate = useNavigate();
  
    return (
        <div className='m-0 p-0 bg-success bg-opacity-10'>
            <h5 className='rounded-top my-0 py-3 bg-success text-white px-2'>CATEGORIES</h5>

            {categories.length > 0 && <div>
                {categories.map((item,i) => {
              
              return (
              <div key={i} className="bg-success bg-opacity-75 border-bottom">
                      <Link style={{'color':'#fff'}} className='btn btn-transparent text-start w-100 text-decoration-none h6' to={`/products/category/${item.pcatslug}`}>{item.pcatname}</Link>
              </div>
              )}) 
              }
              </div>
              }
        </div>
    )
}

export default CategoriesMenu;