import React, { createContext, useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive';

export const SiteContext = createContext();

export const SiteContextProvider = ({ children }) => {
  const apiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PRO;;
  const [userData, setUserData] = useState(null);
  const [siteconfig, setSiteconfig] = useState({});
  const [items, setItems] = useState([]);
  const [pages, setPages] = useState([]);
  const [services, setServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [currentPage, setCurrentPage] = useState('');
  const [cart, setCart] = useState([]);
  const [orderTotal, setOrderTotal] = useState(null);
  const [userToken, setUserToken] = useState(null)
  const [isLoggedOn, setIsLoggedOn] = useState(false);
  const [msg, setMsg] = useState('');
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [latestArticles, setLatestArticles] = useState([]);
  const singleProduct = false;
  const [theme, setTheme] = useState({
    'backgroundColor': 'light',
  'color': 'black',
  'type': 'light'
});
const [currency, setCurrency] = useState('N');
const siteMaxWidth = '1200px';


  useEffect(() => {
    getUserDetails();
    getCartDetails();
    getSiteConfig();
    getProducts();
    getProductCat();
    getServices();
  },[]);


  const getServices = () => {
    fetch(`${apiUrl}/api/pages.php?action=fetchallpages`, {
      method: 'get'
    })
      .then((res) => res.json())
      .then((res) => {

        if (res.status === 200) {
          setPages(res.rows);
        }
      })
      .catch((error) => {
        console.log('Api call error', error.message);
      });
  }

  const getSiteConfig = () => {
    fetch(`${apiUrl}/api/siteupdate.php?action=fetchsiteconfig`, {
      method: 'get'
    })
      .then((res) => {
        return res.json()
      })
      .then(async (response) => {
        setSiteconfig(response.rows);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const getProducts = () => {
    fetch(`${apiUrl}/api/products.php?action=fetch`, {
      method: 'get'
    }).then((res) => {
      return res.json()
    }).then((res) => {
      if (res.status === 200) {
        setProducts(res.products);
        setDiscounts(res.discounts);
      } else if (res.status === 400) {
        setMsg(res.msg);
        setTimeout(() => {
          setMsg('');
        }, 3000)
      }
    }).catch((err) => {
      console.log(err);
    })
  }

  const getProductCat = () => {
    fetch(`${apiUrl}/api/productcategory.php?action=fetch`, {
      method: 'get'
    }).then((res) => {
      return res.json()
    }).then((res) => {
      if (res.status === 200) {
        setCategories(res.categories)
      } else if (res.status === 400) {
        setMsg(res.msg)
        setTimeout(() => {
          setMsg('')
        }, 3000)
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleTheme = (type) => {
    let currentTheme = type === 'light' ? 
  {
    'backgroundColor': 'black',
    'color': 'white',
    'type': 'dark'
  } :
  {
    'backgroundColor': 'light',
  'color': 'black',
  'type': 'light'
}
    setTheme(currentTheme);
  }

  const handleCurrency = (curr) => {
    setCurrency(curr);
  }

  const manageCart = async (action, itemId, itemName, price, totalQty, picture) => {
    let cartContent = cart.slice();
    let item = cartContent.filter((a) => a.id === itemId);

    if (action === '+') {
      if (item.length > 0) {
        let newQty = item[0].qty + 1;
        item[0].qty = newQty;
        item[0].price = price;
        item[0].total = parseFloat(newQty * price).toFixed(2);
      } else {
        let newItem = {
          'id': itemId,
          'item': itemName,
          'qty': 1,
          'price': price,
          'total': price,
          'totalqty': totalQty
        }
        cartContent.push(newItem);
      }
      await setCart(cartContent);
      await localStorage.setItem('cartitems', JSON.stringify(cartContent));
    } else if (action === '-') {
      if (item.length > 0) {
        if (item[0].qty > 1) {
          let newQty = item[0].qty - 1;
          item[0].qty = newQty;
          item[0].price = price;
          item[0].total = parseFloat(newQty * price).toFixed(2);
        }
      }
      await setCart(cartContent);
      await localStorage.setItem('cartitems', JSON.stringify(cartContent));
    } else if (action === 'delete') {
      if (item.length > 0) {
        let items = cartContent.filter((p) => p.id !== itemId);
        await setCart(items);
        await localStorage.removeItem('cartitems');
      }
    } else if (action === 'clear') {
      await setCart([]);
      await localStorage.removeItem('cartitems');
    }
  }

  const manageCartNew = async (itemQty, action, itemId, itemName, price, totalQty, picture) => {
    let cartContent = cart.slice();
    let item = cartContent.filter((a) => a.id === itemId);

    if (item.length > 0 && itemQty === 0) {
      let items = cartContent.filter((p) => p.id !== itemId);
      await setCart(items);
      await localStorage.setItem('cartitems', JSON.stringify(items));
      return;
    }

    if (action === 'update') {
      if (item.length > 0) {
        let newQty = itemQty;
        item[0].qty = newQty;
        item[0].price = price;
        item[0].total = parseFloat(newQty * price).toFixed(2);
        await setCart(cartContent);
        await localStorage.setItem('cartitems', JSON.stringify(cartContent));
      } else {
        let newItem = {
          'id': itemId,
          'item': itemName,
          'qty': itemQty,
          'price': price,
          'total': parseFloat(itemQty * price).toFixed(2),
          'totalqty': totalQty
        }
        cartContent.push(newItem);
        await setCart(cartContent);
        await localStorage.setItem('cartitems', JSON.stringify(cartContent));
      }
    } else if (action === 'delete') {
      if (item.length >= 0) {
        let items = cartContent.filter((p) => p.id !== itemId);
        await setCart(items);
        // await localStorage.removeItem('cartitems');
        await localStorage.setItem('cartitems', JSON.stringify(items));
      }
    } else if (action === 'clear') {
      await setCart([]);
      await localStorage.removeItem('cartitems');
    }
  }

  const getOrderTotal = () => {
    const cartItems = cart.slice();
    const total = cartItems.reduce((a, b) => a + parseFloat(b.total), 0);

    if (total > 0) {
      return parseFloat(total).toFixed(2);
    } else {
      return 0.00;
    }
  }

  const getOrderItemsTotal = () => {
    const cartItems = cart.slice();
    const total = cartItems.reduce((a, b) => a + b.qty, 0);

    if (total > 0) {
      return total;
    } else {
      return '';
    }
  }

  const getItemTotal = (id) => {
    const cartItems = cart.slice();
    const item = cartItems.filter((a) => a.id == id);

    if(item.length > 0) {
      return item[0].qty;
    } else {
      return '0';
    }
}


  const formatAmount = (price) => {
    let dollar = siteconfig.dollarrate;
    let pounds = siteconfig.poundsrate;
    let naira = siteconfig.nairarate;


    if (price > 0) {
      let num = parseFloat(price).toFixed(2);

      num = currency === '$' ? parseFloat(num / dollar).toFixed(2) : currency === '£' ? parseFloat(num / pounds).toFixed(2) : parseFloat(num * naira).toFixed(2);
      num = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      return currency === '$' ? `$${num}` : currency === '£' ? `£${num}` : `N${num}`;
    } else {
      return currency === '$' ? `$0.00` : currency === '£' ? `£0.00` : `N0.00`;
    }
  }

  const nairaValue = (price) => {
    let dollar = siteconfig.dollarrate;
    let pounds = siteconfig.poundsrate;

    let currencyType = price.charAt(0);
    let theprice = parseFloat(price.slice(1));

    if (theprice > 0) {
      let num = currencyType === '$' ? parseFloat(theprice * dollar).toFixed(2) : currencyType === '£' ? parseFloat(theprice * pounds).toFixed(2) : parseFloat(theprice * 1000).toFixed(2);
      return num;
    } else {
      return 0.00;
    }
  }

  const handleCurrentPage = (page) => {
    setCurrentPage(page);
  }

  const handleLatestArticles = (latest) => {
    setLatestArticles(latest)
  }

  const slugify = (string) => {
    return string
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "")
      .replace(/--+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
  }


  const getUserDetails = async () => {
    const fromStorageUser = await localStorage.getItem('user');
    const parsedVUser = JSON.parse(fromStorageUser);
    if (fromStorageUser !== null) {
      await setUserData(parsedVUser);
      await setIsLoggedOn(true);
    }
  };

  const getCartDetails = async () => {
    const fromStorageCart = await localStorage.getItem('cartitems');
    const parsedVCart = JSON.parse(fromStorageCart);
    if (fromStorageCart !== null) {
      await setCart(parsedVCart);
    }
  };

  const handleSignOut = async () => {
    await setUserData(null);
    await setIsLoggedOn(false);
    await localStorage.removeItem('user');
    await localStorage.removeItem('loggedin', false);
  }

  const handleSignIn = async (data) => {
    const storedUser = JSON.stringify(data);

    await setUserData(data);
    await setIsLoggedOn(true);
    await localStorage.setItem('user', storedUser);
    await localStorage.setItem('loggedin', true);
  }

  const formatNumberK = (num) => {
    if (num > 0) {
      let numprice = num / 1000;

      num = numprice.toString();
      return `${num}`;
    } else {
      return '0';
    }
  }


  const contextValue = {
    apiUrl,
    items,
    categories,
    cart,
    pages,
    msg,
    isLoggedOn,
    userData,
    userToken,
    siteconfig,
    orderTotal,
    products,
    discounts,
    isMobile,
    currentPage,
    latestArticles,
    singleProduct,
    theme,
    currency,
    siteMaxWidth,
    nairaValue,
    formatNumberK,
    slugify,
    getOrderTotal,
    setItems,
    manageCart,
    formatAmount,
    getOrderItemsTotal,
    handleSignOut,
    handleSignIn,
    setUserData,
    getServices,
    handleCurrentPage,
    handleLatestArticles,
    handleTheme,
    handleCurrency,
    manageCartNew,
    getItemTotal
  };

  return (
    <SiteContext.Provider value={contextValue}>{children}</SiteContext.Provider>
  );
};
